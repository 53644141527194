import React from "react";
import { SHAPES } from "../helpers/shapes/types";
import ButtonToInput from "./ButtonToInput.tsx";
import ShimmerText from "./ShimmerText.tsx";

import "./Footer.css";

const Footer = ({
  setVisualizationType,
}: {
  setVisualizationType: (type: keyof typeof SHAPES) => void;
}) => {
  return (
    <div id="footer" className="Footer">
      <p className="Footer-text">
        <ShimmerText
          text="Variant"
          handleMouseEnter={() => setVisualizationType("variant")}
        />{" "}
        - we’re building{" "}
        <span>
          <ShimmerText
            text="generative creativity"
            icon="snow"
            handleMouseEnter={() => setVisualizationType("snow")}
          />
        </span>{" "}
        that{" "}
        <ShimmerText
          text="helps you"
          icon="arrow"
          handleMouseEnter={() => setVisualizationType("arrow")}
        />{" "}
        craft{" "}
        <ShimmerText
          text="beautiful designs"
          icon="star"
          handleMouseEnter={() => setVisualizationType("stars")}
        />
        .
      </p>
      <ButtonToInput setVisualizationType={setVisualizationType} />
    </div>
  );
};

export default Footer;
