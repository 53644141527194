import posthog from "posthog-js";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { getPathFromString } from "./helpers/shapes/utils.ts";

posthog.init("phc_bqyh4i61lEPEPfKzXrfGUyVW00cYVWDkGuzGUbhAe1r", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

/* Variant */
// console.log(
//   getPathFromString(
//     "M240.5 238.062L265.491 281.347H15.4971L140.494 64.8464L165.485 108.132L90.4701 238.062H240.5Z",
//   ),
// );
// console.log(
//   getPathFromString(
//     "M465.455 541.124L490.446 497.838L415.437 367.919H565.455L590.446 324.633L340.464 324.633L465.455 541.124Z",
//   ),
// );
// console.log(
//   getPathFromString(
//     "M15.5059 324.633L40.4969 367.919H190.508L115.502 497.832L140.493 541.118L265.481 324.633H15.5059Z",
//   ),
// );
// console.log(
//   getPathFromString(
//     "M277.981 389.553L302.972 346.267L427.967 562.765H177.978L202.969 519.479H352.994L277.981 389.553Z",
//   ),
// );
// console.log(
//   getPathFromString(
//     "M590.458 281.353L541.061 281.356L465.454 151.412L390.433 281.351L340.452 281.35L465.454 64.8403L590.458 281.353Z",
//   ),
// );
// console.log(
//   getPathFromString(
//     "M427.966 43.2012H377.984L302.973 173.124L227.962 43.2012H177.979L302.973 259.696L427.966 43.2012Z",
//   ),
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
