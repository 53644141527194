import React from "react";

const Arrow = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.529864 4.4731L5.00035 0V2.12194L2.86967 4.25387L10.0006 4.2562L10.0001 5.7562L2.87085 5.75387L5 7.88427V10.0062L0.529864 5.53345L0 5.00328L0.529864 4.4731Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Arrow;
