import React, { useState } from "react";

import Footer from "./components/Footer.tsx";
import Shader from "./components/image/Shader.jsx";
import Visualization from "./components/Visualization.tsx";
import { SHAPES } from "./helpers/shapes/types.ts";

import "./App.css";

function App() {
  const [visualizationType, setVisualizationType] =
    useState<keyof typeof SHAPES>("dot");

  return (
    <div className="App">
      <Shader />
      <Visualization
        type={visualizationType}
        setVisualizationType={setVisualizationType}
      />
      <Footer setVisualizationType={setVisualizationType} />
    </div>
  );
}

export default App;
