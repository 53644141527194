import cloneDeep from "lodash/cloneDeep.js";
import { DEFAULT_VISUALIZATION_SIZE } from "../constants.ts";
import { Coordinate, Path, Point, Shape } from "./types.ts";

/* SHAPE -> STRING */

const stringifyCoordinate = (coordinate: Coordinate): string => {
  return coordinate.join(" ");
};

const stringifyPoint = (point: Point): string => {
  let pieces: Array<string> = [point.letter];
  if (point.letter === "Z") {
    // no additional work needed
  } else if (point.letter === "M" || point.letter === "L") {
    pieces.push(stringifyCoordinate(point.value));
  } else if (point.letter === "C") {
    pieces.push(
      stringifyCoordinate(point.handles[0]),
      stringifyCoordinate(point.handles[1]),
      stringifyCoordinate(point.value),
    );
  }
  return pieces.join(" ");
};

const stringifyPath = (path: Path): string => {
  return path.map((point) => stringifyPoint(point)).join(" ");
};

export const stringifyShape = (
  shape: Shape,
): { paths: Array<string>; holes?: Array<string> } => {
  return {
    paths: shape.paths.map((path) => stringifyPath(path)),
    ...(shape.holes
      ? { holes: shape.holes.map((path) => stringifyPath(path)) }
      : null),
  };
};

/* STRING -> SHAPE */

export const getPathFromString = (str: string): Path => {
  // Grab an array of all the letters in the path
  let letters = str.split(/[^A-Z]+/);
  let j = 0;
  while (j < letters.length) {
    const numChars = letters[j].length;
    if (letters[j].length > 1) {
      letters.splice(j, 1, ...letters[j].split(""));
    }
    j += numChars;
  }

  // Grab an array of all the numbers in the path (each number is a separate entry)
  let numbers = str.split(/[\sA-Z,]+/);
  numbers = numbers.filter((number) => number !== "");

  // Fill the path with the points (multiple numbers can go into each point)
  let path: Path = [];
  let startIndex = 0;
  let i = 0;
  for (let j = 0; j < letters.length; j++) {
    const letter = letters[j];
    const prevPoint = path[path.length - 1];
    switch (letter) {
      case "M":
        startIndex = path.length;
      case "L":
        // The next two numbers are a point
        path.push({
          letter,
          value: [Number(numbers[i]), Number(numbers[i + 1])],
        });
        i += 2;
        break;
      case "H":
        // The next number is the x of the point, and the number prior is the y of the point
        if (!prevPoint || prevPoint.letter === "Z") {
          throw new Error("Previous point does not exist");
        }
        const prevY = prevPoint.value[1];
        path.push({
          letter: "L",
          value: [Number(numbers[i]), prevY],
        });
        i += 1;
        break;
      case "V":
        // The next number is the y of the point, and the number two prior is the x of the point
        if (!prevPoint || prevPoint.letter === "Z") {
          throw new Error("Previous point does not exist");
        }
        const prevX = prevPoint.value[0];
        path.push({
          letter: "L",
          value: [prevX, Number(numbers[i])],
        });
        i += 1;
        break;
      case "C":
        // The next two numbers are a handle, the next two after that are another handle, and the next two after that are a point
        path.push({
          letter,
          handles: [
            [Number(numbers[i]), Number(numbers[i + 1])],
            [Number(numbers[i + 2]), Number(numbers[i + 3])],
          ],
          value: [Number(numbers[i + 4]), Number(numbers[i + 5])],
        });
        i += 6;
        break;
      case "Z":
        // Link the previous point (the end point) to the start point, so that we can move the start point when we move the end point
        if (!prevPoint || prevPoint.letter === "Z") {
          throw new Error("Previous point does not exist");
        }
        path.push({
          letter,
        });
        break;
      default:
        throw new Error("Unrecognized letter");
    }
  }
  if (i !== numbers.length) {
    throw new Error("Didn't reach the end of the numbers");
  }

  return path;
};

/* SHAPE SCALING */

export const scaleCoordinate = (
  [x, y]: Coordinate,
  windowWidth: number,
  windowHeight: number,
  size: number,
): Coordinate => {
  const horizontalPadding = (windowWidth - size) / 2;
  const verticalPadding = (windowHeight - size) / 2;
  const scaleFactor = size / DEFAULT_VISUALIZATION_SIZE;

  return [
    x * scaleFactor + horizontalPadding,
    y * scaleFactor + verticalPadding,
  ];
};

export const scaleShape = (
  shape: Shape,
  size: number,
  windowWidth: number,
  windowHeight: number,
): Shape => {
  let scaledShape: Shape = cloneDeep(shape);
  for (let path of scaledShape.paths.concat(scaledShape.holes || [])) {
    for (let point of path) {
      if (point.letter === "Z") {
        continue;
      }
      point.value = scaleCoordinate(
        point.value,
        windowWidth,
        windowHeight,
        size,
      );
      if (point.letter === "C") {
        point.handles = [
          scaleCoordinate(point.handles[0], windowWidth, windowHeight, size),
          scaleCoordinate(point.handles[1], windowWidth, windowHeight, size),
        ];
      }
    }
  }

  return scaledShape;
};

/* SHAPE TRANSITION */

const duplicatePoint = (point: Point): Point => {
  if (point.letter === "Z") {
    throw new Error("Tried to duplicate an endpoint");
  }
  return {
    letter: "L",
    value: [...point.value],
  };
};

const interpolateCoordinates = (
  startCoordinate: Coordinate,
  endCoordinate: Coordinate,
  t: number,
): Coordinate => {
  const [x1, y1] = startCoordinate;
  const [x2, y2] = endCoordinate;
  const xDiff = x2 - x1;
  const yDiff = y2 - y1;
  return [xDiff * t + x1, yDiff * t + y1];
};

const interpolatePoints = (
  startPoint: Point,
  endPoint: Point,
  t: number,
): Point => {
  if (startPoint.letter !== endPoint.letter) {
    throw new Error("Cannot interpolate between two points of different types");
  }
  if (startPoint.letter === "Z" && endPoint.letter === "Z") {
    return {
      letter: "Z",
    };
  }
  if (startPoint.letter === "C" && endPoint.letter === "C") {
    return {
      letter: "C",
      value: interpolateCoordinates(startPoint.value, endPoint.value, t),
      handles: [
        interpolateCoordinates(startPoint.handles[0], endPoint.handles[0], t),
        interpolateCoordinates(startPoint.handles[1], endPoint.handles[1], t),
      ],
    };
  }
  if (
    (startPoint.letter === "M" && endPoint.letter === "M") ||
    (startPoint.letter === "L" && endPoint.letter === "L")
  ) {
    return {
      letter: startPoint.letter,
      value: interpolateCoordinates(startPoint.value, endPoint.value, t),
    };
  }
  throw new Error(
    "Impossible error. Points either do not match or do not have valid letters",
  );
};

const interpolatePaths = (startPath: Path, endPath: Path, t: number): Path => {
  if (startPath.length !== endPath.length) {
    throw new Error("Cannot interpolate two paths of different lengths");
  }
  let interpolatedPath: Path = [];
  for (let i = 0; i < startPath.length; i++) {
    const startPoint: Point = startPath[i];
    const endPoint: Point = endPath[i];
    let interpolatedPoint = interpolatePoints(startPoint, endPoint, t);
    interpolatedPath.push(interpolatedPoint);
  }
  return interpolatedPath;
};

const interpolateShapes = (
  startShape: Shape,
  endShape: Shape,
  t: number,
): Shape => {
  if (startShape.paths.length !== endShape.paths.length) {
    throw new Error(
      "Cannot interpolate two shapes with a different number of paths",
    );
  }
  let interpolatedShape: Shape = {
    paths: [],
  };

  // Interpolate paths
  for (let i = 0; i < startShape.paths.length; i++) {
    const startPath: Path = startShape.paths[i];
    const endPath: Path = endShape.paths[i];
    interpolatedShape.paths.push(interpolatePaths(startPath, endPath, t));
  }

  // Interpolate holes if they exist
  if (
    startShape.holes &&
    endShape.holes &&
    startShape.holes.length === endShape.holes.length
  ) {
    let interpolatedHoles: Array<Path> = [];
    for (let i = 0; i < startShape.holes.length; i++) {
      const startPath: Path = startShape.holes[i];
      const endPath: Path = endShape.holes[i];
      interpolatedHoles.push(interpolatePaths(startPath, endPath, t));
    }
    interpolatedShape.holes = interpolatedHoles;
  }

  return interpolatedShape;
};

// Option 1: elegant (slight bounce at end)
// const TIMING = [
//   0, 0.005927, 0.022466, 0.047872, 0.080554, 0.119068, 0.162116, 0.208536,
//   0.2573, 0.3075, 0.358346, 0.409157, 0.45935, 0.508438, 0.556014, 0.601751,
//   0.645389, 0.686733, 0.72564, 0.762019, 0.795818, 0.827026, 0.855662,
//   0.881772, 0.905423, 0.926704, 0.945714, 0.962568, 0.977386, 0.990295,
//   1.001426, 1.010911, 1.018881, 1.025465, 1.030792, 1.034982, 1.038155,
//   1.040423, 1.041892, 1.042662, 1.042827, 1.042473, 1.04168, 1.040522,
//   1.039065, 1.037371, 1.035493, 1.03348, 1.031376, 1.029217, 1.027037,
//   1.024864, 1.022722, 1.020631, 1.018608, 1.016667, 1.014817, 1.013067,
//   1.011422, 1.009887, 1.008462, 1.007148, 1.005944, 1.004847, 1.003855,
//   1.002964, 1.002169, 1.001466, 1.000848, 1.000311, 0.999849, 0.999457,
//   0.999128, 0.998858, 0.99864, 0.99847, 0.998342, 0.998253, 0.998196,
//   0.998169, 0.998167, 0.998186, 0.998224, 0.998276, 0.998341, 0.998415,
//   0.998497, 0.998584, 0.998675, 0.998768, 0.998861, 0.998954, 0.999045,
//   0.999134, 0.99922, 0.999303, 0.999381, 0.999455, 0.999525, 0.999589,
//   0.99965,
// ];
// const t = TIMING[Math.round(timeElapsed * 100)];

// Option 2: slow (smooth)
// const TIMING = [
//   0, 0.006234, 0.023295, 0.04897, 0.081353, 0.118809, 0.159948, 0.20359,
//   0.248745, 0.294589, 0.34044, 0.385746, 0.43006, 0.473032, 0.514392,
//   0.553942, 0.591539, 0.627092, 0.660552, 0.691902, 0.721157, 0.748351,
//   0.773538, 0.796788, 0.818178, 0.837796, 0.855733, 0.872085, 0.886949,
//   0.90042, 0.912596, 0.923568, 0.933428, 0.942263, 0.950157, 0.957188,
//   0.963433, 0.968961, 0.973838, 0.978127, 0.981885, 0.985164, 0.988015,
//   0.990482, 0.992606, 0.994426, 0.995975, 0.997286, 0.998387, 0.999303,
//   1.000058,
// ];
// const index = Math.min(TIMING.length - 1, Math.round(timeElapsed * 100));
// const t = TIMING[index];

// Option 3: anticipate (slight bounce at start)
// const [x0, y0] = [0, 0];
// const [x1, y1] = [1, -0.4];
// const [x2, y2] = [0.35, 0.95];
// const [x3, y3] = [1, 1];

// const y = (t) =>
//   Math.pow(1 - t, 3) * y0 +
//   3 * Math.pow(1 - t, 2) * t * y1 +
//   3 * (1 - t) * Math.pow(t, 2) * y2 +
//   Math.pow(t, 3) * y3;

// const TIMING: Array<number> = [];
// for (let t = 0; t <= 1; t = t + 1 / 100) {
//   const valY = y(t);
//   TIMING.push(valY);
// }
// const index = Math.min(TIMING.length - 1, Math.round(timeElapsed * 100));
// const t = TIMING[index];

// Option 4: snappy out (smooth) - Currently selected option
// const [x0, y0] = [0, 0];
// const [x1, y1] = [0.19, 1];
// const [x2, y2] = [0.22, 1];
// const [x3, y3] = [1, 1];

// const y = (t) =>
//   Math.pow(1 - t, 3) * y0 +
//   3 * Math.pow(1 - t, 2) * t * y1 +
//   3 * (1 - t) * Math.pow(t, 2) * y2 +
//   Math.pow(t, 3) * y3;

// const TIMING: Array<number> = [];
// for (let t = 0; t <= 1; t = t + 1 / 100) {
//   const valY = y(t);
//   TIMING.push(valY);
// }

const SNAPPY_OUT_TRANSITION_TIMING = [
  0, 0.029700999999999998, 0.05880799999999999, 0.08732699999999999, 0.115264,
  0.14262499999999997, 0.16941599999999998, 0.19564299999999998,
  0.22131200000000004, 0.246429, 0.271, 0.295031, 0.318528, 0.34149699999999994,
  0.363944, 0.38587499999999997, 0.40729599999999994, 0.428213,
  0.44863200000000003, 0.468559, 0.48800000000000004, 0.506961, 0.525448,
  0.5434670000000001, 0.5610240000000002, 0.5781250000000002,
  0.5947760000000002, 0.6109830000000002, 0.6267520000000002,
  0.6420890000000002, 0.6570000000000001, 0.6714910000000002,
  0.6855680000000003, 0.6992370000000002, 0.7125040000000002,
  0.7253750000000003, 0.7378560000000003, 0.7499530000000003,
  0.7616720000000003, 0.7730190000000003, 0.7840000000000003,
  0.7946210000000004, 0.8048880000000003, 0.8148070000000003,
  0.8243840000000002, 0.8336250000000003, 0.8425360000000004,
  0.8511230000000003, 0.8593920000000003, 0.8673490000000004,
  0.8750000000000002, 0.8823510000000002, 0.8894080000000002,
  0.8961770000000002, 0.9026640000000001, 0.9088750000000002,
  0.9148160000000001, 0.9204930000000001, 0.9259120000000002,
  0.9310790000000001, 0.936, 0.9406810000000001, 0.9451280000000002,
  0.9493470000000002, 0.9533440000000002, 0.9571250000000001,
  0.9606960000000002, 0.9640630000000001, 0.9672320000000001,
  0.9702090000000001, 0.9730000000000001, 0.9756110000000001,
  0.9780480000000003, 0.9803170000000001, 0.9824240000000001,
  0.9843750000000001, 0.986176, 0.9878330000000002, 0.9893520000000001,
  0.990739, 0.992, 0.993141, 0.9941679999999999, 0.995087, 0.9959040000000001,
  0.9966250000000001, 0.9972559999999999, 0.997803, 0.998272, 0.998669,
  0.9990000000000001, 0.999271, 0.9994879999999999, 0.999657, 0.999784,
  0.999875, 0.999936, 0.999973, 0.999992, 0.999999,
];

export const getInterpolator = (
  startingShape: Shape,
  endingShape: Shape,
): ((timeElapsed: number) => Shape) => {
  const [matchedStartingShape, matchedEndingShape] = getMatchedShapes(
    startingShape,
    endingShape,
  );
  return (timeElapsed: number): Shape => {
    const index = Math.min(
      SNAPPY_OUT_TRANSITION_TIMING.length - 1,
      Math.round(timeElapsed * 100),
    );
    const t = SNAPPY_OUT_TRANSITION_TIMING[index];

    return interpolateShapes(matchedStartingShape, matchedEndingShape, t);
  };
};

const getCurvePointFromLinePoint = (
  point: Point,
  previousPoint: Point,
): Point => {
  if (point.letter !== "L") {
    throw new Error(
      "Tried to turn something other than a line point into a curve point",
    );
  }
  if (previousPoint.letter === "Z") {
    throw new Error("Previous point is an end point");
  }
  const middlePoint: Coordinate = interpolateCoordinates(
    previousPoint.value,
    point.value,
    0.5,
  );

  return {
    letter: "C",
    value: [...point.value],
    handles: [[...middlePoint], [...middlePoint]],
  };
};

const getConvergedHoles = (holes: Array<Path>): Array<Path> => {
  const convergedHoles: Array<Path> = cloneDeep(holes);
  for (let path of convergedHoles) {
    const pointsWithCoordinates = path.filter((point) => point.letter !== "Z");
    const convergedX =
      pointsWithCoordinates
        .map((point) => point.value[0])
        .reduce((total, x) => total + x) / pointsWithCoordinates.length;
    const convergedY =
      pointsWithCoordinates
        .map((point) => point.value[1])
        .reduce((total, y) => total + y) / pointsWithCoordinates.length;
    for (let point of path) {
      if (point.letter === "Z") {
        continue;
      }
      if (point.letter === "C") {
        point.handles = [
          [convergedX, convergedY],
          [convergedX, convergedY],
        ];
      }
      point.value = [convergedX, convergedY];
    }
  }
  return convergedHoles;
};

const getMatchedShapes = (
  startingShape: Shape,
  endingShape: Shape,
): [Shape, Shape] => {
  let matchedStartingShape: Shape = cloneDeep(startingShape);
  let matchedEndingShape: Shape = cloneDeep(endingShape);

  // Duplicates paths as needed to get the same number of paths in each shape
  if (matchedStartingShape.paths.length !== matchedEndingShape.paths.length) {
    let fewerPathsShape =
      matchedStartingShape.paths.length < matchedEndingShape.paths.length
        ? matchedStartingShape
        : matchedEndingShape;
    let morePathsShape =
      matchedStartingShape.paths.length > matchedEndingShape.paths.length
        ? matchedStartingShape
        : matchedEndingShape;

    let i = 0;
    let loopNumber = 1;
    while (fewerPathsShape.paths.length < morePathsShape.paths.length) {
      // Loop back around
      if (i >= fewerPathsShape.paths.length) {
        i = 0;
        loopNumber += 1;
      }
      // Duplicate the path at the index
      fewerPathsShape.paths.splice(
        i + 1,
        0,
        cloneDeep(fewerPathsShape.paths[i]),
      );
      // Move onto the next unique path
      i += 1 + loopNumber;
    }
  }

  // Adds holes as needed (addded holes will just the matching hole, converged on a single point)
  if (matchedStartingShape.holes && !matchedEndingShape.holes) {
    matchedEndingShape.holes = getConvergedHoles(matchedStartingShape.holes);
  } else if (!matchedStartingShape.holes && matchedEndingShape.holes) {
    matchedStartingShape.holes = getConvergedHoles(matchedEndingShape.holes);
  }

  for (
    let pathIndex = 0;
    pathIndex < matchedStartingShape.paths.length;
    pathIndex++
  ) {
    const startingShapePath = matchedStartingShape.paths[pathIndex];
    const endingShapePath = matchedEndingShape.paths[pathIndex];

    // Duplicates points as needed to get the same length paths
    if (startingShapePath.length !== endingShapePath.length) {
      let fewerPointsPath =
        startingShapePath.length < endingShapePath.length
          ? startingShapePath
          : endingShapePath;
      let morePointsPath =
        startingShapePath.length > endingShapePath.length
          ? startingShapePath
          : endingShapePath;

      let i = 0;
      let loopNumber = 1;
      // TODO: extract this while and the above one into a shared function
      while (fewerPointsPath.length < morePointsPath.length) {
        // Loop back around
        if (i >= fewerPointsPath.length) {
          i = 0;
          loopNumber += 1;
        }
        // Just skip Z's. They don't actually have any coordinates
        if (fewerPointsPath[i].letter === "Z") {
          i += 1;
          continue;
        }
        // Duplicate the path at the index
        const duplicatedPoint = duplicatePoint(fewerPointsPath[i]);
        fewerPointsPath.splice(i + 1, 0, duplicatedPoint);
        // Move onto the next unique path
        i += 1 + loopNumber;
      }
    }

    // Convert lines to curves as needed
    for (let i = 0; i < startingShapePath.length; i++) {
      const startingPoint = startingShapePath[i];
      const endingPoint = endingShapePath[i];
      if (startingPoint.letter === "C" && endingPoint.letter !== "C") {
        endingShapePath[i] = getCurvePointFromLinePoint(
          endingPoint,
          endingShapePath[i - 1],
        );
      } else if (startingPoint.letter !== "C" && endingPoint.letter === "C") {
        startingShapePath[i] = getCurvePointFromLinePoint(
          startingPoint,
          startingShapePath[i - 1],
        );
      }
    }
  }

  return [matchedStartingShape, matchedEndingShape];
};

/* ROTATION */

export const rotatePoint = (x, y, cx, cy, angleDegrees) => {
  // Convert angle to radians
  const angleRad = -angleDegrees * (Math.PI / 180); // Negative for clockwise rotation

  // Translate point to origin
  const xt = x - cx;
  const yt = cy - y; // Invert y to convert to Cartesian

  // Rotate point
  const xr = xt * Math.cos(angleRad) - yt * Math.sin(angleRad);
  const yr = xt * Math.sin(angleRad) + yt * Math.cos(angleRad);

  // Translate point back and convert y back to top-left system
  const xNew = xr + cx;
  const yNew = cy - yr;

  return [xNew, yNew];
};
