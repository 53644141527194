import React, { useRef, useState } from "react";

import RightArrow from "../assets/RightArrow.jsx";
import { SHAPES } from "../helpers/shapes/types";
import { classnames } from "../helpers/utils.ts";
import ShimmerText from "./ShimmerText.tsx";

import { EMAIL_REGEX, SHEET_MONKEY_URL } from "../helpers/constants.ts";
import "./ButtonToInput.css";

type Mode = "button" | "input" | "success";

const IS_DEV = sessionStorage.getItem("VARIANT_DEV_MODE") === "true";

const ButtonToInput = ({
  setVisualizationType,
}: {
  setVisualizationType: (type: keyof typeof SHAPES) => void;
}) => {
  const [mode, setMode] = useState<Mode>("button");
  const [email, setEmail] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isSubmitting = useRef<boolean>(false);

  const handleShowInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMode("input");
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 750);
  };

  const handleError = () => {
    setHasError(true);
    setTimeout(() => {
      setHasError(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }
      isSubmitting.current = false;
    }, 600);
  };

  const handleSuccess = () => {
    setVisualizationType("smile");
    setMode("success");
    isSubmitting.current = false;
  };

  const saveEmail = async () => {
    let formData = new FormData();
    formData.append("Email", email);
    formData.append(
      "Created",
      new Date().toLocaleString("en-US", { timeZone: "PST" }),
    );
    return fetch(SHEET_MONKEY_URL, {
      body: formData,
      method: "post",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Prevent double submit and submitting when form is hidden
    if (isSubmitting.current || mode !== "input") {
      return;
    }
    isSubmitting.current = true;

    if (!email.length || !EMAIL_REGEX.test(email)) {
      handleError();
      return;
    }
    if (IS_DEV) {
      setTimeout(() => {
        handleSuccess();
      }, 500);
      return;
    }

    const res = await saveEmail();
    if (res.status === 200) {
      handleSuccess();
    } else {
      handleError();
    }
  };

  return (
    <div
      className={classnames("ButtonToInput", {
        "is-showingInput": mode === "input",
        "is-showingButton": mode === "button",
        "is-showingSuccess": mode === "success",
        "is-error": hasError,
      })}
    >
      <form method="post" onSubmit={handleSubmit} noValidate>
        <div
          className="ButtonToInput-inputWrapper"
          aria-hidden={mode !== "input"}
        >
          <input
            name="Email"
            disabled={mode !== "input"}
            data-1p-ignore={mode !== "input"}
            data-lpignore={mode !== "input"}
            className="ButtonToInput-input"
            autoComplete={mode === "input" ? "email" : "off"}
            type="email"
            placeholder="Email"
            ref={inputRef}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button
            aria-label="Submit"
            type="submit"
            disabled={mode !== "input"}
            className="ButtonToInput-inputSubmit"
          >
            <RightArrow />
          </button>
        </div>
      </form>
      <button
        aria-hidden={mode !== "button"}
        disabled={mode !== "button"}
        className="ButtonToInput-button"
        onClick={handleShowInput}
      >
        Get early access
      </button>
      <p className="ButtonToInput-successText" aria-hidden={mode !== "success"}>
        <ShimmerText
          text="See you soon"
          icon="smile"
          handleMouseEnter={() => setVisualizationType("smile")}
        />
      </p>
      <div className="ButtonToInput-underline" />
    </div>
  );
};

export default ButtonToInput;
