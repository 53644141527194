const RightArrow = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4583 7.24984L11.5 5.29102V3.16943L15.799 7.46954L16.3292 7.99981L15.799 8.53007L11.5 12.8302V10.7086L13.4583 8.74984L2.39845e-05 8.75005L0 7.25005L13.4583 7.24984Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RightArrow;
