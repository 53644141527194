import React from "react";

const Star = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 10C5 7.23858 2.76142 5 0 5C2.76142 5 5 2.76142 5 0C5 2.76142 7.23858 5 10 5C7.23858 5 5 7.23858 5 10Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Star;
