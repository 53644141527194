export type Coordinate = [number, number];
export type Path = Array<Point>;
export type Shape = { paths: Array<Path>; holes?: Array<Path> };
export type Point =
  | {
      letter: "M" | "L";
      value: Coordinate;
    }
  | {
      letter: "C";
      value: Coordinate;
      handles: [Coordinate, Coordinate];
    }
  | {
      letter: "Z";
    };

export const DOT_SHAPE: Shape = {
  paths: [
    [
      { letter: "M", value: [303, 303] },
      {
        letter: "L",
        value: [303, 303],
      },
      {
        letter: "L",
        value: [303, 303],
      },
      { letter: "Z" },
    ],
  ],
};

export const VARIANT_SHAPE: Shape = {
  paths: [
    [
      {
        letter: "M",
        value: [240.5, 238.062],
      },
      {
        letter: "L",
        value: [265.491, 281.347],
      },
      {
        letter: "L",
        value: [15.4971, 281.347],
      },
      {
        letter: "L",
        value: [140.494, 64.8464],
      },
      {
        letter: "L",
        value: [165.485, 108.132],
      },
      {
        letter: "L",
        value: [90.4701, 238.062],
      },
      {
        letter: "L",
        value: [240.5, 238.062],
      },
      {
        letter: "Z",
      },
    ],
    [
      {
        letter: "M",
        value: [465.455, 541.124],
      },
      {
        letter: "L",
        value: [490.446, 497.838],
      },
      {
        letter: "L",
        value: [415.437, 367.919],
      },
      {
        letter: "L",
        value: [565.455, 367.919],
      },
      {
        letter: "L",
        value: [590.446, 324.633],
      },
      {
        letter: "L",
        value: [340.464, 324.633],
      },
      {
        letter: "L",
        value: [465.455, 541.124],
      },
      {
        letter: "Z",
      },
    ],
    [
      {
        letter: "M",
        value: [15.5059, 324.633],
      },
      {
        letter: "L",
        value: [40.4969, 367.919],
      },
      {
        letter: "L",
        value: [190.508, 367.919],
      },
      {
        letter: "L",
        value: [115.502, 497.832],
      },
      {
        letter: "L",
        value: [140.493, 541.118],
      },
      {
        letter: "L",
        value: [265.481, 324.633],
      },
      {
        letter: "L",
        value: [15.5059, 324.633],
      },
      {
        letter: "Z",
      },
    ],
    [
      {
        letter: "M",
        value: [277.981, 389.553],
      },
      {
        letter: "L",
        value: [302.972, 346.267],
      },
      {
        letter: "L",
        value: [427.967, 562.765],
      },
      {
        letter: "L",
        value: [177.978, 562.765],
      },
      {
        letter: "L",
        value: [202.969, 519.479],
      },
      {
        letter: "L",
        value: [352.994, 519.479],
      },
      {
        letter: "L",
        value: [277.981, 389.553],
      },
      {
        letter: "Z",
      },
    ],
    [
      {
        letter: "M",
        value: [590.458, 281.353],
      },
      {
        letter: "L",
        value: [541.061, 281.356],
      },
      {
        letter: "L",
        value: [465.454, 151.412],
      },
      {
        letter: "L",
        value: [390.433, 281.351],
      },
      {
        letter: "L",
        value: [340.452, 281.35],
      },
      {
        letter: "L",
        value: [465.454, 64.8403],
      },
      {
        letter: "L",
        value: [590.458, 281.353],
      },
      {
        letter: "Z",
      },
    ],
    [
      {
        letter: "M",
        value: [427.966, 43.2012],
      },
      {
        letter: "L",
        value: [377.984, 43.2012],
      },
      {
        letter: "L",
        value: [302.973, 173.124],
      },
      {
        letter: "L",
        value: [227.962, 43.2012],
      },
      {
        letter: "L",
        value: [177.979, 43.2012],
      },
      {
        letter: "L",
        value: [302.973, 259.696],
      },
      {
        letter: "L",
        value: [427.966, 43.2012],
      },
      {
        letter: "Z",
      },
    ],
  ],
};

export const SNOW_SHAPE: Shape = {
  paths: [
    [
      {
        letter: "M",
        value: [281.357, 222.213],
      },
      {
        letter: "L",
        value: [281.357, 43.2856],
      },
      {
        letter: "L",
        value: [324.643, 43.2856],
      },
      {
        letter: "L",
        value: [324.643, 222.222],
      },
      {
        letter: "L",
        value: [414.114, 67.2534],
      },
      {
        letter: "L",
        value: [451.6, 88.8963],
      },
      {
        letter: "L",
        value: [362.136, 243.854],
      },
      {
        letter: "L",
        value: [517.093, 154.389],
      },
      {
        letter: "L",
        value: [538.736, 191.875],
      },
      {
        letter: "L",
        value: [383.768, 281.347],
      },
      {
        letter: "L",
        value: [562.714, 281.347],
      },
      {
        letter: "L",
        value: [562.714, 324.632],
      },
      {
        letter: "L",
        value: [383.768, 324.632],
      },
      {
        letter: "L",
        value: [538.736, 414.103],
      },
      {
        letter: "L",
        value: [517.093, 451.59],
      },
      {
        letter: "L",
        value: [362.136, 362.125],
      },
      {
        letter: "L",
        value: [451.6, 517.082],
      },
      {
        letter: "L",
        value: [414.114, 538.725],
      },
      {
        letter: "L",
        value: [324.643, 383.757],
      },
      {
        letter: "L",
        value: [324.643, 562.714],
      },
      {
        letter: "L",
        value: [281.357, 562.714],
      },
      {
        letter: "L",
        value: [281.357, 383.766],
      },
      {
        letter: "L",
        value: [191.886, 538.735],
      },
      {
        letter: "L",
        value: [154.4, 517.092],
      },
      {
        letter: "L",
        value: [243.877, 362.112],
      },
      {
        letter: "L",
        value: [88.8975, 451.59],
      },
      {
        letter: "L",
        value: [67.2547, 414.103],
      },
      {
        letter: "L",
        value: [222.223, 324.632],
      },
      {
        letter: "L",
        value: [43.2856, 324.632],
      },
      {
        letter: "L",
        value: [43.2856, 281.346],
      },
      {
        letter: "L",
        value: [222.223, 281.346],
      },
      {
        letter: "L",
        value: [67.2547, 191.875],
      },
      {
        letter: "L",
        value: [88.8975, 154.389],
      },
      {
        letter: "L",
        value: [243.877, 243.866],
      },
      {
        letter: "L",
        value: [154.399, 88.887],
      },
      {
        letter: "L",
        value: [191.886, 67.2441],
      },
      {
        letter: "L",
        value: [281.357, 222.213],
      },
      {
        letter: "Z",
      },
    ],
  ],
};

export const SMILE_SHAPE: Shape = {
  paths: [
    [
      {
        letter: "M",
        value: [302.995, 519.92],
      },
      {
        letter: "C",
        handles: [
          [431.782, 519.92],
          [538.717, 426.359],
        ],
        value: [559.539, 303.5],
      },
      {
        letter: "L",
        value: [602.347, 303.501],
      },
      {
        letter: "C",
        handles: [
          [581.129, 449.813],
          [455.187, 562.205],
        ],
        value: [302.995, 562.205],
      },
      {
        letter: "C",
        handles: [
          [150.802, 562.205],
          [24.8601, 449.813],
        ],
        value: [3.64164, 303.501],
      },
      {
        letter: "L",
        value: [46.4499, 303.5],
      },
      {
        letter: "C",
        handles: [
          [67.2723, 426.359],
          [174.207, 519.92],
        ],
        value: [302.995, 519.92],
      },
      {
        letter: "Z",
      },
    ],
    [
      {
        letter: "M",
        value: [173.643, 43.7856],
      },
      {
        letter: "L",
        value: [215.928, 43.7856],
      },
      {
        letter: "L",
        value: [215.928, 302.5],
      },
      {
        letter: "L",
        value: [173.643, 302.5],
      },
      {
        letter: "L",
        value: [173.643, 43.7856],
      },
      {
        letter: "Z",
      },
    ],
    [
      {
        letter: "M",
        value: [390.071, 43.7856],
      },
      {
        letter: "L",
        value: [432.357, 43.7856],
      },
      {
        letter: "L",
        value: [432.357, 302.5],
      },
      {
        letter: "L",
        value: [390.071, 302.5],
      },
      {
        letter: "L",
        value: [390.071, 43.7856],
      },
      {
        letter: "Z",
      },
    ],
  ],
};

export const STARS_SHAPE: Shape = {
  paths: [
    [
      {
        letter: "M",
        value: [43.2856, 346.286],
      },
      {
        letter: "C",
        handles: [
          [138.91, 346.286],
          [216.429, 268.767],
        ],
        value: [216.429, 173.143],
      },
      {
        letter: "L",
        value: [259.714, 173.143],
      },
      {
        letter: "C",
        handles: [
          [259.714, 268.767],
          [337.233, 346.286],
        ],
        value: [432.857, 346.286],
      },
      {
        letter: "L",
        value: [432.857, 389.571],
      },
      {
        letter: "C",
        handles: [
          [337.233, 389.571],
          [259.714, 467.09],
        ],
        value: [259.714, 562.714],
      },
      {
        letter: "L",
        value: [216.429, 562.714],
      },
      {
        letter: "C",
        handles: [
          [216.429, 467.09],
          [138.91, 389.571],
        ],
        value: [43.2856, 389.571],
      },
      {
        letter: "L",
        value: [43.2856, 346.286],
      },
      {
        letter: "Z",
      },
    ],
    [
      {
        letter: "M",
        value: [346.286, 129.857],
      },
      {
        letter: "C",
        handles: [
          [394.098, 129.857],
          [432.857, 91.0977],
        ],
        value: [432.857, 43.2856],
      },
      {
        letter: "L",
        value: [476.143, 43.2856],
      },
      {
        letter: "C",
        handles: [
          [476.143, 91.0977],
          [514.902, 129.857],
        ],
        value: [562.714, 129.857],
      },
      {
        letter: "L",
        value: [562.714, 173.143],
      },
      {
        letter: "C",
        handles: [
          [514.902, 173.143],
          [476.143, 211.902],
        ],
        value: [476.143, 259.714],
      },
      {
        letter: "L",
        value: [432.857, 259.714],
      },
      {
        letter: "C",
        handles: [
          [432.857, 211.902],
          [394.098, 173.143],
        ],
        value: [346.286, 173.143],
      },
      {
        letter: "L",
        value: [346.286, 129.857],
      },
      {
        letter: "Z",
      },
    ],
  ],
  holes: [
    [
      {
        letter: "M",
        value: [137.738, 367.929],
      },
      {
        letter: "C",
        handles: [
          [181.4, 389.14],
          [216.86, 424.6],
        ],
        value: [238.071, 468.262],
      },
      {
        letter: "C",
        handles: [
          [259.283, 424.6],
          [294.742, 389.14],
        ],
        value: [338.405, 367.929],
      },
      {
        letter: "C",
        handles: [
          [294.742, 346.717],
          [259.283, 311.257],
        ],
        value: [238.071, 267.595],
      },
      {
        letter: "C",
        handles: [
          [216.86, 311.257],
          [181.4, 346.717],
        ],
        value: [137.738, 367.929],
      },
      {
        letter: "Z",
      },
    ],
    [
      {
        letter: "M",
        value: [418.092, 151.5],
      },
      {
        letter: "C",
        handles: [
          [432.515, 161.089],
          [444.911, 173.485],
        ],
        value: [454.5, 187.908],
      },
      {
        letter: "C",
        handles: [
          [464.089, 173.485],
          [476.485, 161.089],
        ],
        value: [490.908, 151.5],
      },
      {
        letter: "C",
        handles: [
          [476.485, 141.911],
          [464.089, 129.515],
        ],
        value: [454.5, 115.092],
      },
      {
        letter: "C",
        handles: [
          [444.911, 129.515],
          [432.515, 141.911],
        ],
        value: [418.092, 151.5],
      },
      {
        letter: "Z",
      },
    ],
  ],
};

export const ARROW_SHAPE: Shape = {
  paths: [
    [
      {
        letter: "M",
        value: [113.018, 492.983],
      },
      {
        letter: "L",
        value: [113.018, 174.233],
      },
      {
        letter: "L",
        value: [156.303, 217.519],
      },
      {
        letter: "L",
        value: [156.303, 419.089],
      },
      {
        letter: "L",
        value: [471.344, 104.048],
      },
      {
        letter: "L",
        value: [501.952, 134.656],
      },
      {
        letter: "L",
        value: [186.911, 449.697],
      },
      {
        letter: "L",
        value: [388.482, 449.697],
      },
      {
        letter: "L",
        value: [431.767, 492.983],
      },
      {
        letter: "L",
        value: [113.018, 492.983],
      },
      {
        letter: "Z",
      },
    ],
  ],
};

export const SHAPES: Record<string, Shape> = {
  dot: DOT_SHAPE,
  stars: STARS_SHAPE,
  smile: SMILE_SHAPE,
  snow: SNOW_SHAPE,
  variant: VARIANT_SHAPE,
  arrow: ARROW_SHAPE,
};
