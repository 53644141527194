import React from "react";

const Smile = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 5.00015V0.50293M6.5 5.00015V0.50293"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 5.00022C8.49988 6.93312 6.93292 8.5 5 8.5C3.06708 8.5 1.50012 6.93312 1.5 5.00022H0.000366211V4.93885C0.000122301 4.95921 0 4.97959 0 5C0 7.76142 2.23858 10 5 10C7.76135 10 9.99988 7.76154 10 5.00022H8.5ZM4.95236 0.000222206H5.04764C5.03177 7.41778e-05 5.0159 0 5 0C4.9841 0 4.96823 7.41778e-05 4.95236 0.000222206Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Smile;
