import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DEFAULT_VISUALIZATION_SIZE,
  HORIZONTAL_MARGIN,
  VERTICAL_MARGIN,
} from "../helpers/constants.ts";
import {
  Coordinate,
  Path,
  Point,
  Shape,
  SHAPES,
} from "../helpers/shapes/types.ts";
import {
  getInterpolator,
  rotatePoint,
  scaleCoordinate,
  scaleShape,
  stringifyShape,
} from "../helpers/shapes/utils.ts";
import { classnames, isMobile } from "../helpers/utils.ts";

import "./Visualization.css";

const useRotation = (type: keyof typeof SHAPES) => {
  const isPaused = useRef<boolean>(false);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [rotationDegrees, setRotationDegrees] = useState<number>(0);
  const [shouldTransitionRotation, setShouldTransitionRotation] =
    useState<boolean>(false);

  const isRotatable = type === "arrow";

  const temporarilyAllowRotationTransition = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setShouldTransitionRotation(true);
    timeout.current = setTimeout(() => {
      timeout.current = null;
      setShouldTransitionRotation(false);
    }, 500);
  };

  const handleMouseMove = (evt) => {
    if (isPaused.current) {
      return;
    }
    const mouse = [evt.clientX, evt.clientY];
    const center = [window.innerWidth / 2, window.innerHeight / 2];
    const slope = (mouse[1] - center[1]) / (mouse[0] - center[0]);
    const radians = Math.atan(slope);
    let degrees = (radians * 180) / Math.PI;

    if (evt.clientX > window.innerWidth / 2) {
      degrees += 225;
    } else {
      degrees += 45;
    }
    if (degrees < 0) {
      degrees += 360;
    }
    setRotationDegrees(degrees);
  };

  useEffect(() => {
    // Allow a transition on the rotation only while it is switching between rotatable and not rotatable
    temporarilyAllowRotationTransition();

    if (type !== "arrow") {
      // Trigger rotation transition to 0 degrees
      setRotationDegrees(0);
      return;
    }

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [type]);

  const handleMouseEnter = () => {
    if (isRotatable) {
      isPaused.current = true;
    }
  };

  const handleMouseLeave = () => {
    if (isRotatable) {
      temporarilyAllowRotationTransition();
      isPaused.current = false;
    }
  };

  return {
    shouldTransitionRotation,
    rotationDegrees,
    handleMouseEnter,
    handleMouseLeave,
  };
};

const Visualization = ({
  type,
  setVisualizationType,
}: {
  type: keyof typeof SHAPES;
  setVisualizationType: (type: keyof typeof SHAPES) => void;
}) => {
  const selectedCorner = useRef<{
    pathKey: "paths" | "holes";
    pathIndex: number;
    pointIndex: number;
    isLinkedPoint: boolean;
  } | null>(null);
  const [shape, setShape] = useState<Shape>(cloneDeep(SHAPES[type]));
  const [size, setSize] = useState(0);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [currentType, setCurrentType] = useState<keyof typeof SHAPES>(type);

  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const nextType = useRef<keyof typeof SHAPES | null>(null);

  const {
    shouldTransitionRotation,
    rotationDegrees,
    handleMouseEnter,
    handleMouseLeave,
  } = useRotation(type);

  const unscalePoint = ([x, y]: Coordinate): Coordinate => {
    const horizontalPadding = (windowWidth - size) / 2;
    const verticalPadding = (windowHeight - size) / 2;
    const scaleFactor = size / DEFAULT_VISUALIZATION_SIZE;
    return [
      (x - horizontalPadding) / scaleFactor,
      (y - verticalPadding) / scaleFactor,
    ];
  };

  const updateMeasurements = () => {
    let updatedWindowHeight = window.innerHeight;
    let updatedWindowWidth = window.innerWidth;

    // This is to deal with the scenario where the user zooms in, then hits refresh on mobile.
    // In this scenario, innerWidth/innerHeight correspond to the zoomed in box, and are not accurate.
    // screen.height is also not accurate because it includes the top bar. However screen.width should
    // be accurate, and we can use that plus the height/width ratio to get the actual height
    if (isMobile(navigator.userAgent) && window.innerWidth !== screen.width) {
      const heightToWidthRatio = window.innerHeight / window.innerWidth;
      updatedWindowWidth = screen.width;
      updatedWindowHeight = screen.width * heightToWidthRatio;
    }

    const isNarrowScreen = updatedWindowWidth < 480;

    // The padding on each side should at least be 30% of the size of the graphic
    let graphicSize = Math.min(
      (5 * updatedWindowHeight) / 8,
      isNarrowScreen
        ? // Extra 5px for the rectangles which leak over the sides
          updatedWindowWidth - 2 * (5 + HORIZONTAL_MARGIN)
        : (5 * updatedWindowWidth) / 8,
    );

    // The graphic should not overlap the footer
    const footerElement = document.getElementById("footer");
    // Footer should always exist, but check to satisfy typescript
    if (footerElement) {
      graphicSize = Math.min(
        graphicSize,
        updatedWindowHeight - 2 * footerElement.offsetHeight,
      );
    }

    // The graphic should never exceed the margin around the page
    graphicSize = Math.min(
      graphicSize,
      updatedWindowWidth - 2 * HORIZONTAL_MARGIN,
      updatedWindowHeight - 2 * VERTICAL_MARGIN,
    );

    setSize(graphicSize);
    setWindowWidth(updatedWindowWidth);
    setWindowHeight(updatedWindowHeight);
  };

  const startDrag = (
    evt,
    pathKey: "paths" | "holes",
    pathIndex: number,
    pointIndex: number,
    isLinkedPoint: boolean,
  ) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (selectedCorner.current) {
      return;
    }
    const svg = document.getElementById("visualization-svg");
    if (!svg) {
      throw new Error("There is no svg to drag");
    }

    selectedCorner.current = { pathKey, pathIndex, pointIndex, isLinkedPoint };
    svg.addEventListener("mousemove", drag);
    svg.addEventListener("touchmove", drag);
    svg.addEventListener("mouseup", endDrag);
    svg.addEventListener("touchend", endDrag);
  };

  const drag = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    const svg = document.getElementById("visualization-svg");
    if (!svg) {
      throw new Error("There is no svg to drag");
    }

    if (!selectedCorner.current) {
      throw new Error("No selected corner to drag");
    }

    const { pathIndex, pointIndex, pathKey, isLinkedPoint } =
      selectedCorner.current;

    const [rotatedX, rotatedY] = rotatePoint(
      evt.clientX || evt.touches[0].screenX,
      evt.clientY || evt.touches[0].screenY,
      windowWidth / 2,
      windowHeight / 2,
      -rotationDegrees,
    );
    const [x, y] = unscalePoint([
      rotatedX, //evt.clientX || evt.touches[0].screenX,
      rotatedY, //evt.clientY || evt.touches[0].screenY,
    ]);

    const updatedShape: Shape = cloneDeep(shape);
    if (!updatedShape[pathKey]) {
      throw new Error("Invalid pathKey");
    }
    let path: Path = updatedShape[pathKey][pathIndex];
    if (path[pointIndex].letter === "Z") {
      throw new Error("Tried to move an endpoint");
    }
    path[pointIndex].value = [x, y];
    if (isLinkedPoint && path[0].letter !== "Z") {
      path[0].value = [x, y];
    }
    setShape(updatedShape);
  };

  const endDrag = () => {
    // TODO: end drag if mouse is released off screen
    const svg = document.getElementById("visualization-svg");
    if (!svg) {
      return;
    }

    selectedCorner.current = null;
    svg.removeEventListener("mousemove", drag);
    svg.removeEventListener("touchmove", drag);
    svg.removeEventListener("mouseup", endDrag);
    svg.removeEventListener("touchend", endDrag);
  };

  useEffect(() => {
    window.setTimeout(() => {
      updateMeasurements();
      setIsVisible(true);
      setVisualizationType("variant");
    }, 500);

    // TODO: add a debounce to resize?
    if (!isMobile(navigator.userAgent)) {
      window.addEventListener("resize", updateMeasurements);
      return () => window.removeEventListener("resize", updateMeasurements);
    }
  }, []);

  const transitionShape = (
    startingShape: Shape,
    endingType: keyof typeof SHAPES,
  ) => {
    const endingShape = SHAPES[endingType];

    const interpolator = getInterpolator(startingShape, endingShape);

    // Track updated time locally and update based off this. Avoids the issue where multiple rapid updates result in jerkiness
    let t = 0;
    // TODO: break this out into a separate callback
    interval.current = setInterval(() => {
      if (t >= 1) {
        setCurrentType(endingType);
        setShape(SHAPES[endingType]);
        if (interval.current) {
          clearInterval(interval.current);
        }
        interval.current = null;

        // Check for queued transition
        if (nextType.current) {
          const next = nextType.current;
          nextType.current = null;
          transitionShape(endingShape, next);
        }
        return;
      }

      const interpolatedShape = interpolator(Math.min(t, 1));
      setShape(interpolatedShape);

      t += 0.04;
    }, 10);
  };

  useEffect(() => {
    // Queue up next transition if there is a transition currently in progress
    if (interval.current) {
      // reset interval to be double the time
      nextType.current = type;
      return;
    }
    if (currentType === type) {
      return;
    }

    transitionShape(shape, type);
  }, [type]);

  const scaledShape = scaleShape(shape, size, windowWidth, windowHeight);
  const stringifiedShape = stringifyShape(scaledShape);
  const centerCoordinate = scaleCoordinate(
    [303, 303],
    windowWidth,
    windowHeight,
    size,
  );

  const svgRectangle = (
    point: Point,
    pathKey: "paths" | "holes",
    pathIndex: number,
    pointIndex: number,
    isLinkedPoint: boolean,
  ) => {
    if (point.letter === "M" || point.letter === "Z") {
      return null;
    }
    const [x, y] = point.value;

    return (
      <g key={`${type}-${pathIndex}-${pointIndex}`}>
        <rect
          key={`${type}-${pathIndex}-${pointIndex}`}
          className="Visualization-corner"
          x={x - 5}
          y={y - 5}
          width="10"
          height="10"
          fill="#D9D9D9"
        />
        <rect
          className="Visualization-corner--invisible"
          onMouseDown={(evt) =>
            startDrag(evt, pathKey, pathIndex, pointIndex, isLinkedPoint)
          }
          onTouchStart={(evt) =>
            startDrag(evt, pathKey, pathIndex, pointIndex, isLinkedPoint)
          }
          x={x - 10}
          y={y - 10}
          width="20"
          height="20"
          fill="#D9D9D9"
          opacity="0"
        />
      </g>
    );
  };

  return (
    <div
      className={classnames("Visualization", {
        "is-visible": isVisible,
        "is-transitionRotation": shouldTransitionRotation,
      })}
    >
      <svg
        id="visualization-svg"
        width={`${windowWidth}px`}
        height={`${windowHeight}px`}
        viewBox={`0 0 ${windowWidth} ${windowHeight}`}
        onTouchStart={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
        }}
        onTouchMove={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
        }}
      >
        <defs>
          <mask id="holeMask">
            <rect width="100%" height="100%" fill="white" />
            <g
              className="Visualization-rotatableGroup"
              style={{
                transform: `rotate(${rotationDegrees}deg)`,
              }}
            >
              {stringifiedShape.paths.map((path, pathIndex) => (
                <path
                  key={`${type}-${pathIndex}`}
                  className="Visualization-shape"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d={path}
                  fill="black"
                />
              ))}
              {(stringifiedShape.holes || []).map((path, pathIndex) => (
                <path
                  key={`${type}-${pathIndex}`}
                  className="Visualization-shape"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d={path}
                  fill="white"
                />
              ))}
            </g>
          </mask>
        </defs>

        {/* Black background with shape mask */}
        <rect
          x="0"
          y="0"
          width={windowWidth}
          height={windowHeight}
          fill="#000000"
          mask="url(#holeMask)"
        />

        {/* White border for the shape */}
        <g
          className="Visualization-rotatableGroup"
          style={{
            transform: `rotate(${rotationDegrees}deg)`,
          }}
        >
          {stringifiedShape.paths
            .concat(stringifiedShape.holes || [])
            .map((path, pathIndex) => (
              <path
                className="Visualization-border"
                key={`${type}-${pathIndex}`}
                fillRule="evenodd"
                clipRule="evenodd"
                d={path}
                fill="none"
                stroke="#808080"
                strokeWidth="1"
              />
            ))}
        </g>

        {/* Draggable corners*/}
        <g
          className="Visualization-rotatableGroup"
          style={{
            transform: `rotate(${rotationDegrees}deg)`,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <circle
            cx={centerCoordinate[0]}
            cy={centerCoordinate[1]}
            r={size / 2}
            fill="transparent"
          />
          {stringifiedShape.paths
            .concat(stringifiedShape.holes || [])
            .map((path, pathIndex) => (
              <path
                className="Visualization-paddingVisualization-hoverTarget"
                key={`${type}-${pathIndex}`}
                fillRule="evenodd"
                clipRule="evenodd"
                d={path}
                fill="transparent"
                stroke="transparent"
                strokeWidth="80"
              />
            ))}
          {scaledShape.paths
            .map((path, pathIndex) => {
              return path.map((point, pointIndex) => {
                const isLinkedPoint = pointIndex === path.length - 2;
                return svgRectangle(
                  point,
                  "paths",
                  pathIndex,
                  pointIndex,
                  isLinkedPoint,
                );
              });
            })
            .flat(1)}
          {(scaledShape.holes || [])
            .map((path, pathIndex) => {
              return path.map((point, pointIndex) => {
                const isLinkedPoint = pointIndex === path.length - 2;
                return svgRectangle(
                  point,
                  "holes",
                  pathIndex,
                  pointIndex,
                  isLinkedPoint,
                );
              });
            })
            .flat(1)}
        </g>
      </svg>
      <div className="Visualization-overlay" />
    </div>
  );
};

export default Visualization;
