import React from "react";

const Snow = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 0V10" stroke="currentColor" strokeWidth="1.5" />
      <path d="M10 5L-2.38419e-07 5" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M8.53516 1.46484L1.46409 8.53591"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M8.53516 8.53613L1.46409 1.46506"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Snow;
