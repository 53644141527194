import React from "react";

import Arrow from "../assets/Arrow";
import Smile from "../assets/Smile";
import Snow from "../assets/Snow";
import Star from "../assets/Star";
import { classnames } from "../helpers/utils.ts";

import "./ShimmerText.css";

const ICONS = {
  arrow: <Arrow />,
  smile: <Smile />,
  snow: <Snow />,
  star: <Star />,
};

const ShimmerText = ({
  text,
  icon,
  handleMouseEnter,
}: {
  text: string;
  icon?: keyof typeof ICONS;
  handleMouseEnter: () => void;
}) => {
  // const chars = text.split("");
  // // Array of random numbers from 0 to 2
  // const offsets = chars.map(() => Math.random() * 2);

  return (
    <span
      className={classnames("ShimmerText", { "ShimmerText--emphasis": !icon })}
      onMouseEnter={handleMouseEnter}
    >
      {text}
      {/* {chars.map((char, index) => {
        const offset = offsets[index];
        return (
          <span
            key={index}
            className="ShimmerText-character"
            style={{ animationDelay: `${-1 * offset}s` }}
          >
            {char}
          </span>
        );
      })} */}{" "}
      <span className="ShimmerText-icon">{icon ? ICONS[icon] : null}</span>
    </span>
  );
};

export default ShimmerText;
